$black: #000;
$white: #fff;


$section-title:#151819;
$desc:#596066;
$nav-bg:#F4F6FB;
$comp-title:#939CA2;
$nav-link:#596066;
$nav-active:#34647F;
$comp-border:#DCDCDC;
$code-bg:#F3F7F9;