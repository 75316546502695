@import '/src/assets/helpers/index';

.comp-title{
font-size: 23px;
font-weight: 600;
line-height: 29.9px;
color: $section-title;
text-transform: capitalize;
}
.card-wrapper{
border: 1px solid $comp-border;
margin-top: 24px;
border-radius: 8px;
}
.comp-container{
  display: flex;
  justify-content: center;
  padding: 42px;
  border-bottom: 1px solid $comp-border;
}
.code-container{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 28px 24px;
  background-color: $code-bg;
}
.code-title{
  color: $black;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 8px;
}
.code-snippet{
  color: $black;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
.copy-btn{
  padding: 6px;
  background-color: $white;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color .4s;
  &:hover{
    background-color: $comp-title;
    svg{
      path{
        fill: $white;
      }
    }
  }
}
.footer-text{
  display: flex;
  gap: 6px;
  align-items: center;
  margin-top: 12px;
}
.link-info{
  color: $desc;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
}
.footer-link{
  cursor: pointer;
  text-decoration: underline;
  color: $section-title;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.8px;

  &:hover{
    text-decoration: none;
  }
}