/**
 * Do not edit directly
 * Generated on Fri, 15 Mar 2024 04:53:58 GMT
 */

:root {
  --dss-background-button-fill-primary: #4e46b4;
  --dss-background-button-fill-hovered: #4740a4;
  --dss-background-button-fill-pressed: #4740a4;
  --dss-background-button-fill-disabled: #17171740;
  --dss-background-button-fill-icon-fill: #ffffff;
  --dss-background-button-stroke-primary: #4e46b4;
  --dss-background-button-stroke-hovered: #e0dff5;
  --dss-background-button-stroke-pressed: #e0dff5;
  --dss-background-button-stroke-disabled: #17171740;
  --dss-background-button-stroke-icon-fill: #4e46b4;
  --dss-background-button-danger-primary: #dc4440;
  --dss-background-button-danger-hovered: #d31510;
  --dss-background-button-danger-pressed: #d31510;
  --dss-background-button-danger-disabled: #17171740;
  --dss-background-button-default-primary: #e0dff5;
  --dss-background-button-default-hovered: #c8c6e8;
  --dss-background-button-default-pressed: #c8c6e8;
  --dss-background-button-default-disabled: #1717170d;
  --dss-background-button-ghost-primary: #4e46b4;
  --dss-background-button-ghost-hovered: #e0dff5;
  --dss-background-button-ghost-pressed: #e0dff5;
  --dss-background-button-ghost-icon-fill: #4e46b4;
  --dss-background-surface: #ffffff;
  --dss-background-negative: #d31510;
  --dss-background-disabled: #efefef;
  --dss-background-caution: #f26a2f;
  --dss-background-positive: #0a8a00;
  --dss-background-informative: #0058a3;
  --dss-background-inverse: #171717;
  --dss-background-primary: #efefef;
  --dss-background-secondary: #c2c2c2;
  --dss-background-tertiary: #a3a3a3;
  --dss-background-selected: #4e46b4;
  --dss-background-on-bg-hover: #ffffff26;
  --dss-background-empty: #ffffff00;
  --dss-text-button-fill-primary: #ffffff;
  --dss-text-button-stroke-primary: #4e46b4;
  --dss-text-button-stroke-disabled: #17171740;
  --dss-text-button-default-primary: #4e46b4;
  --dss-text-button-default-disabled: #17171740;
  --dss-text-button-danger-primary: #ffffff;
  --dss-text-button-ghost-primary: #4e46b4;
  --dss-text-button-ghost-disabled: #17171740;
  --dss-text-button-link-primary: #4e46b4;
  --dss-text-button-link-hovered: #4740a4;
  --dss-text-button-link-pressed: #4740a4;
  --dss-text-button-link-disabled: #17171740;
  --dss-text-button-link-negative: #dc4440;
  --dss-text-primary: #171717;
  --dss-text-disabled: #17171740;
  --dss-text-secondary: #434343;
  --dss-text-tertiary: #6d6d6d;
  --dss-text-placeholder: #c2c2c2;
  --dss-text-negative: #d31510;
  --dss-text-caution: #f26a2f;
  --dss-text-positive: #0a8a00;
  --dss-text-informative: #0058a3;
  --dss-text-on-bg-fill: #ffffff;
  --dss-border-primary: #c2c2c2;
  --dss-border-secondary: #a3a3a3;
  --dss-border-tertiary: #6d6d6d;
  --dss-border-negative: #d31510;
  --dss-border-disabled: #efefef;
  --dss-border-caution: #f26a2f;
  --dss-border-positive: #0a8a00;
  --dss-border-informative: #0058a3;
  --dss-primary-100: #e0dff5;
  --dss-primary-200: #c8c6e8;
  --dss-primary-300: #aeaadd;
  --dss-primary-400: #8883cd;
  --dss-primary-500: #716bc3;
  --dss-primary-600: #4e46b4;
  --dss-primary-700: #4740a4;
  --dss-primary-800: #373280;
  --dss-primary-900: #2b2763;
  --dss-primary-1000: #211d4c;
  --dss-supporting-color1-100: #fdf7ea;
  --dss-supporting-color1-200: #f8e6bf;
  --dss-supporting-color1-300: #f5d9a0;
  --dss-supporting-color1-400: #f0c875;
  --dss-supporting-color1-500: #edbd5a;
  --dss-supporting-color1-600: #e9ad31;
  --dss-supporting-color1-700: #d49d2d;
  --dss-supporting-color1-800: #a57b23;
  --dss-supporting-color1-900: #805f1b;
  --dss-supporting-color1-1000: #624915;
  --dss-supporting-color2-100: #eaf8f3;
  --dss-supporting-color2-200: #bee8d9;
  --dss-supporting-color2-300: #9eddc7;
  --dss-supporting-color2-400: #72cdad;
  --dss-supporting-color2-500: #57c49d;
  --dss-supporting-color2-600: #2db585;
  --dss-supporting-color2-700: #29a579;
  --dss-supporting-color2-800: #20815e;
  --dss-supporting-color2-900: #196449;
  --dss-supporting-color2-1000: #134c38;
  --dss-supporting-color3-100: #eaf1fa;
  --dss-supporting-color3-200: #bdd4ee;
  --dss-supporting-color3-300: #9dc0e6;
  --dss-supporting-color3-400: #70a3da;
  --dss-supporting-color3-500: #5591d3;
  --dss-supporting-color3-600: #2a75c8;
  --dss-supporting-color3-700: #266ab6;
  --dss-supporting-color3-800: #1e538e;
  --dss-supporting-color3-900: #17406e;
  --dss-supporting-color3-1000: #123154;
  --dss-secondary-100: #ffece6;
  --dss-secondary-200: #ffc5b0;
  --dss-secondary-300: #ffa98a;
  --dss-secondary-400: #ff8254;
  --dss-secondary-500: #ff6933;
  --dss-secondary-600: #ff4400;
  --dss-secondary-700: #e83e00;
  --dss-secondary-800: #b53000;
  --dss-secondary-900: #8c2500;
  --dss-secondary-1000: #6b1d00;
  --dss-neutrals-100: #ffffff;
  --dss-neutrals-200: #efefef;
  --dss-neutrals-300: #c2c2c2;
  --dss-neutrals-400: #a3a3a3;
  --dss-neutrals-500: #6d6d6d;
  --dss-neutrals-600: #585858;
  --dss-neutrals-700: #434343;
  --dss-neutrals-800: #353535;
  --dss-neutrals-900: #1f1f1f;
  --dss-neutrals-1000: #171717;
  --dss-shadow-0: 0px 0px 0px 0px #171717;
  --dss-shadow-100: 0px 1px 0px 0px #17171780;
  --dss-shadow-200: 0px 3px 1px -1px #17171780;
  --dss-shadow-300: 0px 4px 6px -2px #17171780;
  --dss-shadow-400: 0px 8px 16px -4px #17171780;
  --dss-shadow-500: 0px 12px 20px -8px #17171780;
  --dss-shadow-600: 0px 20px 20px -8px #17171780;
  --dss-shadow-fill-pressed-extra-large: 0px 0px 0px 6px #c8c6e8;
  --dss-shadow-fill-pressed-large: 0px 0px 0px 5px #c8c6e8;
  --dss-shadow-fill-pressed-normal: 0px 0px 0px 3px #c8c6e8;
  --dss-shadow-fill-pressed-dense: 0px 0px 0px 3px #c8c6e8;
  --dss-shadow-fill-pressed-denser: 0px 0px 0px 3px #c8c6e8;
  --dss-shadow-default-pressed-extra-large: 0px 0px 0px 6px #e0dff5;
  --dss-shadow-default-pressed-large: 0px 0px 0px 5px #e0dff5;
  --dss-shadow-default-pressed-normal: 0px 0px 0px 3px #e0dff5;
  --dss-shadow-default-pressed-dense: 0px 0px 0px 3px #e0dff5;
  --dss-shadow-default-pressed-denser: 0px 0px 0px 3px #e0dff5;
  --dss-shadow-danger-pressed-extra-large: 0px 0px 0px 6px #f1b6b5;
  --dss-shadow-danger-pressed-large: 0px 0px 0px 5px #f1b6b5;
  --dss-shadow-danger-pressed-normal: 0px 0px 0px 3px #f1b6b5;
  --dss-shadow-danger-pressed-dense: 0px 0px 0px 3px #f1b6b5;
  --dss-shadow-danger-pressed-denser: 0px 0px 0px 3px #f1b6b5;
  --dss-shadow-strock-pressed-extra-large: 0px 0px 0px 6px #e0dff5;
  --dss-shadow-strock-pressed-large: 0px 0px 0px 5px #e0dff5;
  --dss-shadow-strock-pressed-normal: 0px 0px 0px 3px #e0dff5;
  --dss-shadow-strock-pressed-dense: 0px 0px 0px 3px #e0dff5;
  --dss-shadow-strock-pressed-denser: 0px 0px 0px 3px #e0dff5;
  --dss-shadow-ghost-pressed-extra-large: 0px 0px 0px 6px #c8c6e8;
  --dss-shadow-ghost-pressed-large: 0px 0px 0px 5px #c8c6e8;
  --dss-shadow-ghost-pressed-normal: 0px 0px 0px 3px #c8c6e8;
  --dss-shadow-ghost-pressed-dense: 0px 0px 0px 3px #c8c6e8;
  --dss-shadow-ghost-pressed-denser: 0px 0px 0px 3px #c8c6e8;
  --dss-font-family-primary: Inter;
  --dss-font-family-secondary: Inter;
  --dss-line-height-90: 90%;
  --dss-line-height-100: 100%;
  --dss-line-height-120: 120%;
  --dss-line-height-130: 130%;
  --dss-line-height-140: 140%;
  --dss-line-height-150: 150%;
  --dss-font-weight-regular: 400;
  --dss-font-weight-medium: 500;
  --dss-font-weight-semi-bold: 600;
  --dss-font-weight-bold: 700;
  --dss-font-size-100: 11px;
  --dss-font-size-200: 13px;
  --dss-font-size-300: 14px;
  --dss-font-size-400: 16px;
  --dss-font-size-500: 18px;
  --dss-font-size-600: 20px;
  --dss-font-size-700: 23px;
  --dss-font-size-800: 26px;
  --dss-font-size-900: 29px;
  --dss-font-size-1000: 32px;
  --dss-font-letter-spacing-normal: 0px;
  --dss-font-letter-spacing-dense: -0.2px;
  --dss-font-letter-spacing-denser: -0.3px;
  --dss-font-letter-spacing-densest: -0.54px;
  --dss-text-case-none: none;
  --dss-text-case-uppercase: uppercase;
  --dss-paragraph-spacing-0: 0px;
  --dss-paragraph-spacing-100: 8px;
  --dss-paragraph-spacing-150: 12px;
  --dss-paragraph-spacing-200: 16px;
  --dss-text-decoration-underline: underline;
  --dss-text-decoration-none: none;
  --dss-text-decoration-strike-through: line-through;
  --dss-border-radius-0: 0px;
  --dss-border-radius-50: 2px;
  --dss-border-radius-100: 4px;
  --dss-border-radius-150: 6px;
  --dss-border-radius-200: 8px;
  --dss-border-radius-300: 12px;
  --dss-border-radius-400: 16px;
  --dss-border-radius-500: 20px;
  --dss-border-radius-700: 28px;
  --dss-border-radius-full: 9999px;
  --dss-space-0: 0px;
  --dss-space-25: 1px;
  --dss-space-50: 2px;
  --dss-space-100: 4px;
  --dss-space-150: 6px;
  --dss-space-200: 8px;
  --dss-space-300: 12px;
  --dss-space-400: 16px;
  --dss-space-500: 20px;
  --dss-space-600: 24px;
  --dss-space-800: 32px;
  --dss-space-1000: 40px;
  --dss-space-1200: 48px;
  --dss-space-1600: 64px;
  --dss-space-2000: 80px;
  --dss-space-2400: 96px;
  --dss-space-2800: 112px;
  --dss-space-3200: 128px;
  --dss-space-button-extra-large: 16px 24px;
  --dss-space-button-large: 12px 20px;
  --dss-space-button-normal: 12px 16px;
  --dss-space-button-dense: 8px 12px;
  --dss-space-button-denser: 4px 8px;
  --dss-ui-text-lg-medium: [object Object];
  --dss-ui-text-lg-semi-bold: [object Object];
  --dss-ui-text-md-medium: [object Object];
  --dss-ui-text-md-semi-bold: [object Object];
  --dss-ui-text-sm-medium: [object Object];
  --dss-ui-text-sm-semi-bold: [object Object];
  --dss-ui-text-xs-medium: [object Object];
  --dss-ui-text-xs-semi-bold: [object Object];
  --dss-ui-text-xxs-medium: [object Object];
  --dss-ui-text-xxs-semi-bold: [object Object];
  --dss-body-lg: [object Object];
  --dss-body-md: [object Object];
  --dss-body-sm: [object Object];
  --dss-body-xs: [object Object];
  --dss-body-xxs: [object Object];
  --dss-body-compact-lg: [object Object];
  --dss-body-compact-md: [object Object];
  --dss-body-compact-sm: [object Object];
  --dss-body-compact-xs: [object Object];
  --dss-body-compact-xxs: [object Object];
  --dss-heading-3xl: [object Object];
  --dss-heading-2xl: [object Object];
  --dss-heading-xl: [object Object];
  --dss-heading-lg: [object Object];
  --dss-heading-md: [object Object];
  --dss-heading-sm: [object Object];
  --dss-heading-xs: [object Object];
  --dss-border-width-0: 0px;
  --dss-border-width-50: 0.5px;
  --dss-border-width-100: 1px;
  --dss-border-width-150: 1.5px;
  --dss-border-width-200: 2px;
  --dss-border-width-250: 2.5px;
  --dss-border-width-300: 3px;
  --dss-border-width-400: 4px;
  --dss-border-width-500: 5px;
  --dss-size-25: 1px;
  --dss-size-50: 2px;
  --dss-size-100: 4px;
  --dss-size-200: 8px;
  --dss-size-300: 12px;
  --dss-size-400: 16px;
  --dss-size-500: 20px;
  --dss-size-600: 24px;
  --dss-size-800: 32px;
  --dss-size-1000: 40px;
  --dss-size-1200: 48px;
  --dss-size-1400: 56px;
  --dss-size-1600: 64px;
  --dss-size-1800: 72px;
  --dss-size-2000: 80px;
  --dss-size-2400: 96px;
  --dss-size-2500: 100px;
  --dss-size-2600: 104px;
  --dss-size-2800: 112px;
  --dss-size-3200: 128px;
  --dss-alpha-neutrals-100-a: #17171708;
  --dss-alpha-neutrals-200-a: #1717170d;
  --dss-alpha-neutrals-300-a: #17171726;
  --dss-alpha-neutrals-400-a: #17171740;
  --dss-alpha-neutrals-500-a: #17171780;
  --dss-alpha-neutrals-0-a: #ffffff00;
  --dss-alpha-semantic-200-a: #e0075126;
  --dss-alpha-dark-neutral-100-a: #ffffff80;
  --dss-alpha-dark-neutral-200-a: #ffffff40;
  --dss-alpha-dark-neutral-300-a: #ffffff26;
  --dss-alpha-dark-neutral-400-a: #ffffff0d;
  --dss-alpha-dark-neutral-500-a: #ffffff08;
  --dss-semantics-negative-100: #fbe8e7;
  --dss-semantics-negative-200: #f1b6b5;
  --dss-semantics-negative-300: #eb9391;
  --dss-semantics-negative-400: #e2625f;
  --dss-semantics-negative-500: #dc4440;
  --dss-semantics-negative-600: #d31510;
  --dss-semantics-negative-700: #c0130f;
  --dss-semantics-negative-800: #960f0b;
  --dss-semantics-negative-900: #740c09;
  --dss-semantics-negative-1000: #590907;
  --dss-semantics-caution-100: #fef0ea;
  --dss-semantics-caution-200: #fbd1bf;
  --dss-semantics-caution-300: #f9ba9f;
  --dss-semantics-caution-400: #f69b74;
  --dss-semantics-caution-500: #f58859;
  --dss-semantics-caution-600: #f26a2f;
  --dss-semantics-caution-700: #dc602b;
  --dss-semantics-caution-800: #ac4b21;
  --dss-semantics-caution-900: #853a1a;
  --dss-semantics-caution-1000: #662d14;
  --dss-semantics-positive-100: #e7f3e6;
  --dss-semantics-positive-200: #b3dbb0;
  --dss-semantics-positive-300: #8ec98a;
  --dss-semantics-positive-400: #5bb154;
  --dss-semantics-positive-500: #3ba133;
  --dss-semantics-positive-600: #0a8a00;
  --dss-semantics-positive-700: #097e00;
  --dss-semantics-positive-800: #076200;
  --dss-semantics-positive-900: #064c00;
  --dss-semantics-positive-1000: #043a00;
  --dss-semantics-informative-100: #e6eef6;
  --dss-semantics-informative-200: #b0cbe2;
  --dss-semantics-informative-300: #8ab2d5;
  --dss-semantics-informative-400: #548fc1;
  --dss-semantics-informative-500: #3379b5;
  --dss-semantics-informative-600: #0058a3;
  --dss-semantics-informative-700: #005094;
  --dss-semantics-informative-800: #003e74;
  --dss-semantics-informative-900: #003e74;
  --dss-semantics-informative-1000: #002544;
  --dss-token-set-order-0: core;
  --dss-token-set-order-1: light;
}
