// media querry variable
$breakpoints: (
	desktop: 1248px,
	tablet: 991px,
	phone: 767px,
	phone-sm: 575px,
);

@mixin mq($width, $type: max) {
	@if map_has_key($breakpoints, $width) {
		$width: map_get($breakpoints, $width);

		@if $type ==min {
			$width: $width + 1px;
		}

		@media only screen and (#{$type}-width: $width) {
			@content;
		}
	}
}