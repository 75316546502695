.textarea{
  --_textarea-wrapper-gap:var(--dss-space-100);
  --_textarea-label-color:var(--dss-text-secondary);
  --_textarea-label-font-weight:var(--dss-font-weight-medium);
  --_textarea-label-font-size:var(--dss-font-size-200);
  --_textarea-label-line-height:var(--dss-line-height-120);
  --_textarea-paddingX:var(--dss-space-300);
  --_textarea-paddingY:var(--dss-space-200);
  --_textarea-bg-color:var(--dss-background-surface);
  --_textarea-border-width:var(--dss-border-width-100);
  --_textarea-border-color:var(--dss-border-primary);
  --_textarea-active-border-color:var(--dss-border-tertiary);
  --_textarea-border-radius:var(--dss-border-radius-100);
  --_textarea-placeholder-color:var(--dss-text-placeholder);
  --_textarea-placeholder-font-weight:var(--dss-font-weight-regular);
  --_textarea-placeholder-font-size:var(--dss-font-size-300);
--_input-validation-content-gap:var(--dss-space-100);
  --_textarea-text-color:var(--dss-text-primary);
  --_textarea-caret-color:var(--dss-background-inverse);
  --_textarea-cursor:text;
  --_textarea-validation-color:var(--dss-text-negative);
  --_textarea-validation-font-size:var(--dss-font-size-200);
  --_textarea-validation-line-height:var(--dss-line-height-150);
  --_textarea-validation-spacing:var(--dss-font-letter-spacing-normal);
  --_textarea-count-container-gap:var(--dss-space-200);
  
  --_textarea-count-text-color:var(--dss-text-tertiary);
  --_textarea-count-text-font-size:var(--dss-font-size-200);
  --_textarea-count-text-font-weight:var(--dss-font-weight-regular);
  --_textarea-count-text-line-height:var(---dss-line-height-120);
  --_textarea-count-text-spacing:var(--dss-font-letter-spacing-normal);
   &-wrapper{
  display: flex;
  flex-direction: column;
  gap: var(--_textarea-wrapper-gap);
   }
   &-label{
  color: var(--_textarea-label-color);
  text-transform: capitalize;
  font-weight: var(--_textarea-label-font-weight);
  font-size: var(--_textarea-label-font-size);
  line-height: var(--_textarea-label-line-height);

   }
   &-container{
    display: flex;
    flex-direction: column;
    gap: var(--_textarea-wrapper-gap);
  padding: var(--_textarea-paddingY) var(--_textarea-paddingX);
  background-color: var(--_textarea-bg-color);
  border:var(--_textarea-border-width) solid var(--_textarea-border-color);
  border-radius: var(--_textarea-border-radius);
  cursor: var(--_textarea-cursor);

  
  
  .textarea-field{
    min-width: 469px;
    min-height: 144px;
    border: none;
    background-color: var(--_textarea-bg-color);
    cursor: var(--_textarea-cursor);
    caret-color: var(--_textarea-caret-color);
    outline: none;
    color: var(--_textarea-text-color);
    font-weight: var(--_textarea-placeholder-font-weight);
    font-size: var(--_textarea-placeholder-font-size);
    resize: none;
    &::placeholder{
      color: var(--_textarea-placeholder-color);
      font-weight: var(--_textarea-placeholder-font-weight);
      font-size: var(--_textarea-placeholder-font-size);
    }
  }
  &.focused{
    --_textarea-border-color:var(--_textarea-active-border-color);
  }
  &.error{
    --_textarea-border-color:var(--dss-border-negative);
  }
}
&-fluid{
 --_textarea-label-color:var(--dss-text-tertiary);
 --_textarea-label-font-weight:var(--dss-font-weight-regular);
 --_textarea-label-font-size:var(--dss-font-size-300);
}
   &-disabled{
    --_textarea-label-color:var(--dss-text-disabled);
    --_textarea-border-color:var(--dss-border-disabled);
    --_textarea-cursor:no-drop;
    --_textarea-count-text-color:var(--dss-text-disabled);
    --_textarea-label-color:var(--dss-text-disabled);

   }
   &-read-only{
    --_textarea-bg-color:var(--dss-background-disabled);
    --_textarea-active-border-color:var(--dss-border-primary)
   }
   .error-container{
    display: flex;
  align-items: center;
  gap: var(--_input-validation-content-gap);
    transform: translateY(-6px);
    opacity: 0;
    visibility: hidden;
    transition: all .4s;
    z-index: -1;
    &.active{
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
    .error-message{
   color: var(--_textarea-validation-color);
   font-size: var(--_textarea-validation-font-size);
   letter-spacing: var(--_textarea-validation-spacing);
   line-height: var(--_textarea-validation-line-height);
    }
   }

   .count-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--_textarea-count-container-gap);
    .count-text{
      color: var(--_textarea-count-text-color);
 font-size: var(--_textarea-count-text-font-size);
 font-weight: var(--_textarea-count-text-font-weight);
 line-height: var(--_textarea-count-text-line-height);
 letter-spacing: var(--_textarea-count-text-spacing);
    }
   }
  }