.avatar {
  --_dss-avatar-size: var(--dss-size-800);
  --_dss-avatar-font-color: var(--dss-text-on-bg-fill);
  --_dss-avatar-font-size: var(--dss-font-size-300);
  --_dss-avatar-font-weight: var(--dss-font-weight-semi-bold);
  --_dss-avatar-line-height: var(--dss-line-height-120);
  --_dss-avatar-bg-color: var(--dss-background-selected);
  --_dss-avatar-border-width: var(--dss-border-width-0);
  --_dss-avatar-border-color: var(--dss-background-surface);
  --_dss-avatar-border-radius: var(--dss-border-radius-200);
  --_dss-avatar-status-size: var(--dss-size-300);
  --_dss-avatar-active-size: var(--dss-size-200);
  --_dss-avatar-user-size: 100%;
  box-sizing: border-box;
  color: var(--_dss-avatar-font-color);
  text-align: center;
  font-size: var(--_dss-avatar-font-size);
  font-weight: var(--_dss-avatar-font-weight);
  line-height: var(--_dss-avatar-line-height);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--_dss-avatar-bg-color);
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  width: var(--_dss-avatar-size);
  height: var(--_dss-avatar-size);
  flex-shrink: 0;
  border: var(--_dss-avatar-border-width) solid var(--_dss-avatar-border-color);
  border-radius: var(--_dss-avatar-border-radius);

  &:has(img) {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &:has(svg) {
    --_dss-avatar-user-size: var(--dss-size-400);

    svg {
      display: inline-flex;
      align-items: center;
      min-width: var(--_dss-avatar-user-size);
      height: var(--_dss-avatar-user-size);
      flex-shrink: 0;
    }
  }

  &-xxl {
    --_dss-avatar-size: var(--dss-size-3200);
    --_dss-avatar-font-size: var(--dss-font-size-1000);
    --_dss-avatar-font-weight: var(--dss-font-weight-bold);

    &:has(svg) {
      --_dss-avatar-user-size: var(--dss-size-1800);
    }
  }

  &-xl {
    --_dss-avatar-size: var(--dss-size-2400);
    --_dss-avatar-font-size: var(--dss-font-size-900);
    --_dss-avatar-font-weight: var(--dss-font-weight-bold);

    &:has(svg) {
      --_dss-avatar-user-size: var(--dss-size-1400);
    }
  }

  &-lg {
    --_dss-avatar-size: var(--dss-size-1000);
    --_dss-avatar-font-size: var(--dss-font-size-500);
    --_dss-avatar-font-weight: var(--dss-font-weight-bold);

    &:has(svg) {
      --_dss-avatar-user-size: var(--dss-size-600);
    }
  }

  &-sm {
    --_dss-avatar-size: var(--dss-size-600);
    --_dss-avatar-font-size: var(--dss-font-size-100);

    &:has(svg) {
      --_dss-avatar-user-size: var(--dss-size-300);
    }
  }

  &-xs {
    --_dss-avatar-size: var(--dss-size-400);
    --_dss-avatar-font-size: var(--dss-font-size-100);

    &:has(svg) {
      --_dss-avatar-user-size: var(--dss-size-200);
    }
  }

  &-circle {
    --_dss-avatar-border-radius: var(--dss-border-radius-full);
  }
}

.avatar-badge {
  --_dss-avatar-badge-border-color: var(--dss-text-on-bg-fill);
  --_dss-avatar-badge-border-width: var(--dss-border-width-100);
  --_dss-avatar-badge-bg-color: var(--dss-background-positive);
  --_dss-avatar-badge-padding: var(--dss-space-100);
  --_dss-avatar-status-top-position: 0;
  --_dss-avatar-status-right-position: 0;
  --_dss-avatar-active-right-position: 0;
  --_dss-avatar-active-bottom-position: 0;
  --_dss-avatar-status-size: var(--dss-size-200);
  position: relative;

  .avatar {
    &-xxl+.avatar-status {
      --_dss-avatar-status-size: var(--dss-size-600);
      --_dss-avatar-badge-border-width: 2px;
      --_dss-avatar-status-top-position: 8px;
      --_dss-avatar-status-right-position: 8px;
    }

    &-xxl+.avatar-active {
      --_dss-avatar-active-size: var(--dss-size-500);
      --_dss-avatar-badge-border-width: 2px;
      --_dss-avatar-active-right-position: 17px;
    }

    &-xl+.avatar-status {
      --_dss-avatar-status-size: var(--dss-size-500);
      --_dss-avatar-status-right-position: 5px;
    }

    &-xl+.avatar-active {
      --_dss-avatar-active-size: var(--dss-size-300);
      --_dss-avatar-active-right-position: 9px;
      --_dss-avatar-active-bottom-position: 7px;
    }

    &-lg+.avatar-status {
      --_dss-avatar-status-size: var(--dss-size-300);
      --_dss-avatar-badge-padding: 2px;
    }

    &-lg+.avatar-active {
      --_dss-avatar-active-size: var(--dss-size-200);
      --_dss-avatar-active-right-position: 2px;
      --_dss-avatar-active-bottom-position: 2px;
    }

    &-status {
      display: inline-flex;
      width: var(--_dss-avatar-status-size);
      height: var(--_dss-avatar-status-size);
      padding: var(--_dss-avatar-badge-padding);
      position: absolute;
      right: var(--_dss-avatar-status-top-position);
      top: var(--_dss-avatar-status-right-position);
      border-radius: var(--dss-border-radius-full);
      border: var(--_dss-avatar-badge-border-width) solid var(--_dss-avatar-badge-border-color);
      background: var(--_dss-avatar-badge-bg-color);
    }

    &-active {
      display: inline-flex;
      width: var(--_dss-avatar-active-size);
      height: var(--_dss-avatar-active-size);
      position: absolute;
      right: var(--_dss-avatar-active-right-position);
      bottom: var(--_dss-avatar-active-bottom-position);
      border-radius: var(--dss-border-radius-full);
      border: var(--_dss-avatar-badge-border-width) solid var(--_dss-avatar-badge-border-color);
      background: var(--_dss-avatar-badge-bg-color);
    }
  }
}

.avatar-group {
  display: flex;
  position: relative;
  $avatar-count: attr(data-avatar-count);
  flex-direction: row-reverse;
  justify-content: flex-end;

  .avatar {
    --_dss-avatar-border-width: var(--dss-border-width-200);
    margin-right: -6px;
  }

  .avatar-remaining {
    --_dss-avatar-bg-color: var(--dss-background-secondary);
    --_dss-avatar-font-color: var(--dss-text-primary);
    --_dss-avatar-font-size: var(--dss-font-size-200);

    &.avatar-xs,
    &.avatar-sm {
      --_dss-avatar-font-size: var(--dss-font-size-100);
    }

    &.avatar-lg {
      --_dss-avatar-font-size: var(--dss-font-size-300);
    }

    &.avatar-xl {
      --_dss-avatar-font-size: var(--dss-font-size-400);
    }

    &.avatar-xxl {
      --_dss-avatar-font-size: var(--dss-font-size-500);
    }
  }

  &-count {
    --_dss-avatar-bg-color: var(--dss-background-secondary);
    --_dss-avatar-border-width: --dss-border-width-0;
    --_dss-avatar-font-color: var(--dss-text-primary);
    --_dss-avatar-font-weight: var(--dss-font-weight-medium);
  }
}