.checkbox-wrapper{
  --_checkbox-border-width:2px;
  --_checkbox-border-color:#A3A3A3;
  --_checkbox-border-color-hovered:#4E46B4;
  --_checkbox-border-color-active:#4E46B4;
  --_checkbox-bg-color:white;
  --_checkbox-bg-color-active:#4E46B4;
  --_checkbox-border-color-disabled:#EFEFEF;
  --_checkbox-icon-color:white;
  --_checkbox-icon-size:var(--dss-size-300);
  --_checkbox-size:var(--dss-space-500);
  --_checkbox-border-radius:25%;
  --_checkbox-label-gap:8px;
  --_checkbox-label-font-color:#171717;
  --_checkbox-label-font-weight:400;
  --_checkbox-label-font-size:var(--dss-font-size-300);
  --_checkbox-label-font-lineheight:120%;
  --_checkbox-label-font-spacing:0px;
    display: flex;
  align-items: center;
  gap: var(--_checkbox-label-gap);
  cursor: pointer;
  .checkbox{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: var(--_checkbox-size);
    height: var(--_checkbox-size);
    border-radius: var(--_checkbox-border-radius);
    border: var(--_checkbox-border-width) solid var(--_checkbox-border-color);
    outline: none;
    background-color: var(--_checkbox-bg-color);
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;
    &::after {
      // content: url(../../../../public/images/svg/check-icon.svg); 
      content: '\2713';
      font-size: var(--_checkbox-icon-size); 
      color: var(--_checkbox-icon-color);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
    }
    &:checked{
      --_checkbox-border-color:var(--_checkbox-border-color-active);
      background-color: var(--_checkbox-bg-color-active);
      &::after{
        display: block;
      }
  }
  &-label{
    cursor: pointer;
    color: var(--_checkbox-label-font-color);
    font-weight: var(--_checkbox-label-font-weight);
    font-size: var(--_checkbox-label-font-size);
    line-height: var(--_checkbox-label-font-lineheight);
    letter-spacing: var(--_checkbox-label-font-spacing);
    &-large{
      --_checkbox-label-font-size:var(--dss-font-size-400);
    }
    &-small{
      --_checkbox-label-font-size:var(--dss-font-size-200);
    }
    &-disabled{
      --_checkbox-label-font-color:var(--dss-text-disabled);
    }
      }
  &:disabled{
    --_checkbox-border-color-hovered:var(--dss-background-disabled);
    --_checkbox-border-color:var(--dss-background-disabled);
    --_checkbox-bg-color-active:var(--dss-background-surface);
    --_checkbox-icon-color:var(--dss-background-disabled)
  }
  &:hover{
    --_checkbox-border-color:var(--_checkbox-border-color-hovered);
  }
  &-large{
    --_checkbox-size:var(--dss-space-600);
    --_checkbox-icon-size:var(--dss-size-400);
    --_checkbox-label-font-size:var(--dss-font-size-400);
  }
  &-small{
    --_checkbox-size:var(--dss-space-400);
    --_checkbox-icon-size:var(--dss-size-200);
    --_checkbox-label-font-size:var(--dss-font-size-200);
  }
  &-default{
    --_checkbox-border-color:var(--dss-background-tertiary);
    --_checkbox-border-color-hovered:var(--dss-background-selected);
    --_checkbox-border-color-active:var(--dss-background-selected);
    --_checkbox-bg-color-active:var(--dss-background-selected);
    --_checkbox-icon-color:var(--dss-background-surface);
  }
  &-outline{
    --_checkbox-border-color:var(--dss-background-tertiary);
    --_checkbox-border-color-hovered:var(--dss-background-selected);
    --_checkbox-border-color-active:var(--dss-background-selected);
    --_checkbox-bg-color-active:var(--dss-background-surface);
    --_checkbox-icon-color:var(--dss-background-selected);
  }
  }
}