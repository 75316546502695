.input{
--_input-wrapper-gap:var(--dss-space-100);
--_input-label-color:var(--dss-text-secondary);
--_input-label-font-weight:var(--dss-font-weight-medium);
--_input-label-font-size:var(--dss-font-size-200);
--_input-paddingX:var(--dss-space-300);
--_input-paddingY:var(--dss-space-300);
--_input-bg-color:var(--dss-background-surface);
--_input-border-width:var(--dss-border-width-100);
--_input-border-color:var(--dss-border-primary);
--_input-active-border-color:var(--dss-border-tertiary);
--_input-border-radius:var(--dss-border-radius-100);
--_input-placeholder-color:var(--dss-text-placeholder);
--_input-placeholder-font-weight:var(--dss-font-weight-regular);
--_input-placeholder-font-size:var(--dss-font-size-400);
--_input-text-color:var(--dss-text-primary);
--_input-caret-color:var(--dss-background-inverse);
--_input-cursor:text;
--_input-container-gap:var(--dss-space-200);
--_input-prefix-color:var(--dss-text-tertiary);
--_input-prefix-font-weight:var(--dss-font-weight-regular);
--_input-prefix-font-size:var(--dss-font-size-300);
--_input-prefix-line-height:var(--dss-line-height-120);
--_input-prefix-spacing:var(--dss-font-letter-spacing-normal);
--_input-validation-content-gap:var(--dss-space-100);
--_input-validation-color:var(--dss-text-negative);
--_input-validation-font-size:var(--dss-font-size-200);
--_input-validation-line-height:var(--dss-line-height-150);
--_input-validation-spacing:var(--dss-font-letter-spacing-normal);

 &-wrapper{
display: flex;
flex-direction: column;
gap: var(--_input-wrapper-gap);
 }
 &-label{
color: var(--_input-label-color);
text-transform: capitalize;
font-weight: var(--_input-label-font-weight);
font-size: var(--_input-label-font-size);
 }
 &-container{
  display: flex;
  align-items: center;
  gap: var(--_input-container-gap);
padding: var(--_input-paddingY) var(--_input-paddingX);
background-color: var(--_input-bg-color);
border:var(--_input-border-width) solid var(--_input-border-color);
border-radius: var(--_input-border-radius);
cursor: var(--_input-cursor);


.input-field{
  border: none;
  background-color: var(--_input-bg-color);
  cursor: var(--_input-cursor);
  caret-color: var(--_input-caret-color);
  outline: none;
  color: var(--_input-text-color);
  font-weight: var(--_input-placeholder-font-weight);
  font-size: var(--_input-placeholder-font-size);
  resize: none;
  &::placeholder{
    color: var(--_input-placeholder-color);
    font-weight: var(--_input-placeholder-font-weight);
    font-size: var(--_input-placeholder-font-size);
  }
}
&.focused{
  --_input-border-color:var(--_input-active-border-color);
}
&.error{
  --_input-border-color:var(--dss-border-negative);
}
 }
 &.disabled{
  --_input-label-color:var(--dss-text-disabled);
  --_input-border-color:var(--dss-border-disabled);
  --_input-cursor:no-drop;
 }
 &.read-only{
  --_input-bg-color:var(--dss-background-disabled);
  --_input-active-border-color:var(--dss-border-primary)
 }
 .prefix-text{
color: var(--_input-prefix-color);
font-weight: var(--_input-prefix-font-weight);
font-size: var(--_input-prefix-font-size);
line-height: var(--_input-prefix-line-height);
letter-spacing: var(--_input-prefix-spacing);
 }

 .error-container{
  display: flex;
  align-items: center;
  gap: var(--_input-validation-content-gap);
  transform: translateY(-6px);
  opacity: 0;
  visibility: hidden;
  transition: all .4s;
  z-index: -1;
  &.active{
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
 }
 .error-message{
color: var(--_input-validation-color);
font-size: var(--_input-validation-font-size);
letter-spacing: var(--_input-validation-spacing);
line-height: var(--_input-validation-line-height);
 }
}