.btn{
  --_btn-width:auto;
  --_btn-padding:var(--dss-space-button-normal);
  --_btn-bg-color:rgb(110, 110, 110);
  --_btn-bg-color-hovered:gray;
  --_btn-bg-disabled:rgb(212, 212, 212);
  --_btn-text-color:white;
  --_btn-text-color-hovered:white;
  --_btn-text-color-pressed:white;
  --_btn-border-radius:8px;
  --_btn-border-color:transparent;
  --_btn-disabled-border-color:transparent;
  --_btn-text-disabled-color:white;
  --_btn-text-font-size:var(--dss-font-size-300);
  --_btn-text-font-weight:500;
  --_btn-text-line-height:120%;
  --_btn-text-decoration:none;
  --_btn-text-decoration-hovered:none;
  --_btn-content-gap:0;
  --_btn-cursor:pointer;
  --_btn-shadow:none;
  --_btn-shadow-pressed:none;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--_btn-width);
  gap:var(--_btn-content-gap);
  cursor: var(--_btn-cursor);
  padding: var(--_btn-padding);
  background-color: var(--_btn-bg-color);
color: var(--_btn-text-color);
border-radius: var(--_btn-border-radius);
border-color: var(--_btn-border-color);
text-align: center;
transition: all .4s;
text-transform: capitalize;
font-size: var(--_btn-text-font-size);
font-weight: var(--_btn-text-font-weight);
line-height: var(--_btn-text-line-height);
text-decoration: var(--_btn-text-decoration);
box-shadow: var(--_btn-shadow);
&:hover{
  --_btn-bg-color: var(--_btn-bg-color-hovered);
  --_btn-text-decoration:var(--_btn-text-decoration-hovered);
  --_btn-text-color:var(--_btn-text-color-hovered);
}
&:active{
  --_btn-text-color:var(--_btn-text-color-pressed);
  --_btn-shadow:var(--_btn-shadow-pressed);
}
&.disabled{
  --_btn-bg-color: var(--_btn-bg-disabled);
  --_btn-cursor: no-drop;
  --_btn-text-color: var(--_btn-text-disabled-color);
  --_btn-border-color: var(--_btn-disabled-border-color);
  --_btn-shadow-pressed:none;
}
&-xl{
  --_btn-text-font-size:var(--dss-font-size-500);
  --_btn-padding: var(--dss-space-button-extra-large);
}
&-lg{
  --_btn-text-font-size:var(--dss-font-size-400);
--_btn-padding: var(--dss-space-button-large);
}
&-sm{
  --_btn-text-font-size:var(--dss-font-size-200);
  --_btn-padding: var(--dss-space-button-dense);
}
&-xs{
  --_btn-text-font-size:var(--dss-font-size-100);
  --_btn-padding: var(--dss-space-button-denser);
}
&-fluid{
  --_btn-width:100%;
}
&-primary{
  --_btn-bg-color:var(--dss-background-button-fill-primary);
  --_btn-text-color:var(--dss-text-button-fill-primary);
  --_btn-text-color-hovered:var(--dss-text-button-fill-primary);
  --_btn-text-color-pressed:var(--dss-text-button-fill-primary);
  --_btn-border-radius:var(--dss-border-radius-200);
  --_btn-border-color:transparent;
  --_btn-bg-color-hovered:var(--dss-background-button-fill-hovered);
  --_btn-bg-disabled:var(--dss-background-button-fill-disabled);
  --_btn-text-disabled-color:var(--dss-text-button-fill-primary);
  --_btn-text-font-weight:var(--dss-font-weight-medium);
  --_btn-text-line-height:var(--dss-line-height-120);
  --_btn-shadow-pressed:var(--dss-shadow-fill-pressed-normal);
  &.btn{
    &-xl{
      --_btn-shadow-pressed:var(--dss-shadow-fill-pressed-extra-large);
    }
    &-lg{
      --_btn-shadow-pressed:var(--dss-shadow-fill-pressed-large);
    }
    &-sm{
      --_btn-shadow-pressed:var(--dss-shadow-fill-pressed-dense);
    }
    &-xs{
      --_btn-shadow-pressed:var(--dss-shadow-fill-pressed-denser);
    }
  }
}
&-secondary{
  --_btn-bg-color:var(--dss-background-button-default-primary);
  --_btn-text-color:var(--dss-text-button-default-primary);
  --_btn-text-color-hovered:var(--dss-text-button-default-primary);
  --_btn-text-color-pressed:var(--dss-text-button-default-primary);
  --_btn-border-radius:var(--dss-border-radius-200);
  --_btn-border-color:transparent;
  --_btn-bg-color-hovered:var(--dss-background-button-default-hovered);
  --_btn-bg-disabled:var(--dss-background-button-default-disabled);
  --_btn-text-disabled-color:var(--dss-text-button-default-disabled);
  --_btn-text-font-weight:var(--dss-font-weight-medium);
  --_btn-text-line-height:var(--dss-line-height-120);      --_btn-shadow-pressed:var(--dss-shadow-default-pressed-normal);
  &.btn{
    &-xl{
      --_btn-shadow-pressed:var(--dss-shadow-default-pressed-extra-large);
    }
    &-lg{
      --_btn-shadow-pressed:var(--dss-shadow-default-pressed-large);
    }
    &-sm{
      --_btn-shadow-pressed:var(--dss-shadow-default-pressed-dense);
    }
    &-xs{
      --_btn-shadow-pressed:var(--dss-shadow-default-pressed-denser);
    }
  }
}
&-outline{
  --_btn-bg-color:var(--dss-background-empty);
  --_btn-text-color:var(--dss-text-button-stroke-primary);
  --_btn-text-color-hovered:var(--dss-text-button-stroke-primary);
  --_btn-text-color-pressed:var(--dss-text-button-stroke-primary);
  --_btn-border-radius:var(--dss-border-radius-200);
  --_btn-border-color:var(--dss-background-button-stroke-primary);
  --_btn-bg-color-hovered:var(--dss-background-button-stroke-hovered);
  --_btn-text-font-weight:var(--dss-font-weight-medium);
  --_btn-text-line-height:var(--dss-line-height-120);
  --_btn-bg-disabled:var(--dss-background-empty);
  --_btn-text-disabled-color:var(--dss-text-button-stroke-disabled);
  --_btn-disabled-border-color:var(--dss-background-button-stroke-disabled);      --_btn-shadow-pressed:var(--dss-shadow-strock-pressed-normal);
  &.btn{
    &-xl{
      --_btn-shadow-pressed:var(--dss-shadow-strock-pressed-extra-large);
    }
    &-lg{
      --_btn-shadow-pressed:var(--dss-shadow-strock-pressed-large);
    }
    &-sm{
      --_btn-shadow-pressed:var(--dss-shadow-strock-pressed-dense);
    }
    &-xs{
      --_btn-shadow-pressed:var(--dss-shadow-strock-pressed-denser);
    }
  }
}
&-danger{
  --_btn-bg-color:var(--dss-background-button-danger-primary);
  --_btn-text-color:var(--dss-text-button-danger-primary);
  --_btn-text-color-hovered:var(--dss-text-button-danger-primary);
  --_btn-text-color-pressed:var(--dss-text-button-danger-primary);
  --_btn-border-radius:var(--dss-border-radius-200);
  --_btn-border-color:transparent;
  --_btn-bg-color-hovered:var(--dss-background-button-danger-hovered);
  --_btn-text-font-weight:var(--dss-font-weight-medium);
  --_btn-text-line-height:var(--dss-line-height-120);
  --_btn-bg-disabled:var(--dss-background-button-danger-disabled);
  --_btn-text-disabled-color:var(--dss-text-button-danger-primary);
  --_btn-disabled-border-color:transparent;      --_btn-shadow-pressed:var(--dss-shadow-danger-pressed-normal);
  &.btn{
    &-xl{
      --_btn-shadow-pressed:var(--dss-shadow-danger-pressed-extra-large);
    }
    &-lg{
      --_btn-shadow-pressed:var(--dss-shadow-danger-pressed-large);
    }
    &-sm{
      --_btn-shadow-pressed:var(--dss-shadow-danger-pressed-dense);
    }
    &-xs{
      --_btn-shadow-pressed:var(--dss-shadow-danger-pressed-denser);
    }
  }
}
&-ghost{
  --_btn-bg-color:var(--dss-background-empty);
  --_btn-text-color:var(--dss-text-button-ghost-primary);
  --_btn-text-color-hovered:var(--dss-text-button-ghost-primary);
  --_btn-text-color-pressed:var(--dss-text-button-ghost-primary);
  --_btn-border-radius:var(--dss-border-radius-200);
  --_btn-border-color:transparent;
  --_btn-bg-color-hovered:var(--dss-background-button-ghost-hovered);
  --_btn-text-font-weight:var(--dss-font-weight-medium);
  --_btn-text-line-height:var(--dss-line-height-120);
  --_btn-bg-disabled:var(--dss-background-empty);
  --_btn-text-disabled-color:var(--dss-text-button-ghost-disabled);
  --_btn-disabled-border-color:transparent;      --_btn-shadow-pressed:var(--dss-shadow-ghost-pressed-normal);
  &.btn{
    &-xl{
      --_btn-shadow-pressed:var(--dss-shadow-ghost-pressed-extra-large);
    }
    &-lg{
      --_btn-shadow-pressed:var(--dss-shadow-ghost-pressed-large);
    }
    &-sm{
      --_btn-shadow-pressed:var(--dss-shadow-ghost-pressed-dense);
    }
    &-xs{
      --_btn-shadow-pressed:var(--dss-shadow-ghost-pressed-denser);
    }
  }
}
&-link{
  --_btn-padding:var(--dss-space-0);
  --_btn-bg-color:var(--dss-background-empty);
  --_btn-text-color:var(--dss-text-button-link-primary);
  --_btn-text-color-hovered:var(--dss-text-button-link-hovered);
  --_btn-text-color-pressed:var(--dss-text-button-link-pressed);
  --_btn-border-radius:var(--dss-border-radius-0);
  --_btn-border-color:transparent;
  --_btn-bg-color-hovered:transparent;
  --_btn-text-font-weight:var(--dss-font-weight-medium);
  --_btn-text-line-height:var(--dss-line-height-120);
  --_btn-bg-disabled:var(--dss-background-empty);
  --_btn-text-disabled-color:var(--dss-text-button-link-disabled);
  --_btn-disabled-border-color:transparent;
  --_btn-text-decoration:var(--dss-text-decoration-underline);
  --_btn-text-decoration-hovered:none;
}
&-icon{
  --_btn-content-gap:8px;
}
}

