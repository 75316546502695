@import '../helpers/index';


// font family
.font-family-primary {
  font-family: $base-font-family;
}
.section-title{
  font-size: 34px;
  line-height: 44.2px;
  font-weight: 600;
  color: $section-title;
  text-transform: capitalize;
}
.comp-desc{
  font-size: 16px;
  line-height: 22.2px;
  font-weight: 400;
  color: $desc;
}