@import '/src/assets/helpers/index';
.main-container{
  // display: grid;
  display: flex;
  // grid-template-columns: 250px 1fr;
  height: 100vh;
  width: 100%;
}
.navbar{
  background-color: $nav-bg;
  padding: 24px 40px;
  position: fixed;
  height: 100vh;
  min-width: 250px;
}
.component-text{
  color: $comp-title;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.4px;
  margin-top: 24px;
  text-transform: capitalize;
}
.link-wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}
.nav-link{
  color: $nav-link;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  transition: color .4s;
  text-transform: capitalize;
  width: fit-content;
  cursor: pointer;
  text-decoration: none;

  &:hover{
    color: $nav-active;
  }
&.active{
  color: $nav-active;
}
}
.main-content{
 margin-left: 250px;
 width: 100%;
}
.header{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 48px 28px 20px 40px;
  margin-bottom: 10px;
  border-bottom: 1px solid $nav-link;
  background-color: $white;
}
.component-title{
  font-size: 48px;
  color: $black;
  font-weight: 700;
  line-height: 48px;
  text-transform: capitalize;
}
// .theme-btn{
// border-color:red;
// padding: 3px;
// border-radius: 25%;
// background-color: $nav-active;
// cursor: pointer;
// svg{
//   path{
//     stroke: $nav-bg;
//   }
// }
// }
.content-section{
  padding: 10px 28px 48px 40px;
  max-height: calc(100vh - 140px);
  overflow-y: auto;
}