// fit element based on the available space

.cursor {
  cursor: pointer;
}

.flex-fit {
  min-width: 0;
  flex: 1 1 0%;
}

.d-none {
  display: none;
}
.d-flex{
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

/* Flex direction */
.flex-row-reverse {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

/* Flex wrap */
.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/* Justify content */
.justify-content-end {
  justify-content: flex-end;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-around {
  justify-content: space-around;
}

// .justify-content-between {
//   justify-content: space-between;
// }

/* Align items */
.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-stretch {
  align-items: stretch;
}

.align-items-baseline {
  align-items: baseline;
}

/* Flex grow */
.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

/* Flex shrink */
.flex-0 {
  flex-shrink: 0;
}

.flex-1 {
  flex-shrink: 1;
}

/* Flex basis */
.flex-basis-auto {
  flex-basis: auto;
}

.flex-basis-0 {
  flex-basis: 0;
}

/* Align self */
.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-stretch {
  align-self: stretch;
}

.align-self-baseline {
  align-self: baseline;
}

.img-fluid {
  max-width: 100%;
}

.w-100 {
  width: 100%;
}


// gap
.gap-32{
  gap: 32px;
}
.gap-52{
  gap: 52px;
}